*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:checked, [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial;
}

.hum-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%232A313F' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%232A313F' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@font-face {
  font-family: Supera Gothic Bold;
  src: url("SuperaGothic-Bold.ea0a105c.otf");
  font-weight: 600;
}

@font-face {
  font-family: Supera Gothic Extra Bold;
  src: url("SuperaGothic-ExtraBold.fe02338d.otf");
  font-weight: 700;
}

@font-face {
  font-family: Supera Gothic Black;
  src: url("SuperaGothic-Black.2ec0b3d1.otf");
  font-weight: 800;
}

.flex-between {
  justify-content: space-between;
  display: flex;
}

.bg-default {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-default:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-secondary:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.text-color {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-color:where(.dark, .dark *), .text-inverted {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-inverted:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.fill-subdued {
  fill: #6b7280;
}

.stroke-subdued {
  stroke: #6b7280;
}

.subdued {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-disabled {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.border-default {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-default:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.display-m {
  cursor: default;
  letter-spacing: .0016em;
  font-family: Supera Gothic Black;
  font-size: 2rem;
  line-height: 2.5rem;
}

.display-s {
  cursor: default;
  letter-spacing: .0016em;
  font-family: Supera Gothic Black;
  font-size: 1.5rem;
  line-height: 2rem;
}

.pageheading {
  cursor: default;
  letter-spacing: .0016em;
  font-family: Supera Gothic Extra Bold;
  font-size: 1.25rem;
  line-height: 2rem;
}

.heading-l {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.heading-m {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.heading-s {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.subheading {
  cursor: default;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Inter;
  font-size: .688rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.body-l {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.body-m {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-s {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.ui-element {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.ui-label, .body-micro {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

.text-button {
  cursor: pointer;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.lozenge {
  cursor: default;
  text-transform: uppercase;
  letter-spacing: .16px;
  font-family: Inter;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

* {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

body:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

body::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius);
}

body::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}

body::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}

body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}

body::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}

body::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
  border-radius: var(--scrollbar-corner-radius);
}

body::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
}

body::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

body {
  --scrollbar-track: #282828;
  --scrollbar-thumb: #494949;
}

label {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5rem;
}

a {
  cursor: pointer;
  letter-spacing: 0;
  --tw-text-opacity: 1;
  color: rgb(74 102 244 / var(--tw-text-opacity));
  font-family: Inter;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

a:hover {
  --tw-text-opacity: 1;
  color: rgb(74 102 244 / var(--tw-text-opacity));
}

a:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(146 171 250 / var(--tw-text-opacity));
}

a > svg {
  cursor: pointer;
  letter-spacing: 0;
  --tw-text-opacity: 1;
  color: rgb(74 102 244 / var(--tw-text-opacity));
  font-family: Inter;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

a > svg:hover {
  --tw-text-opacity: 1;
  color: rgb(74 102 244 / var(--tw-text-opacity));
}

a > svg:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(146 171 250 / var(--tw-text-opacity));
  fill: #92abfa;
  stroke: #92abfa;
}

input, input[type="text"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

input:where(.dark, .dark *), input[type="text"]:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

input:focus, input[type="text"]:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

input:focus:where(.dark, .dark *), input[type="text"]:focus:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

input, input[type="text"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 100%;
  margin-top: .5rem;
  margin-bottom: .25rem;
  padding: .5rem .5rem .5rem .75rem;
}

input::placeholder, input[type="text"]::placeholder {
  background-color: #0000;
}

input:disabled, input[type="text"]:disabled {
  background-color: #fff3;
}

input, input[type="text"] {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

input:where(.dark, .dark *), input[type="text"]:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

input, input[type="text"] {
  outline-offset: 2px;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  border-width: 1px;
  border-radius: .25rem;
  outline: 2px solid #0000;
}

input:where(.dark, .dark *), input[type="text"]:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

input:autofill, input[type="text"]:autofill {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

input:autofill:where(.dark, .dark *), input[type="text"]:autofill:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

input::placeholder, input[type="text"]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

input:disabled::placeholder, input[type="text"]:disabled::placeholder {
  color: #fff3;
}

input:focus, input[type="text"]:focus {
  --tw-ring-color: transparent;
}

input:focus::placeholder, input[type="text"]:focus::placeholder {
  color: #0000;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  box-shadow: inset 0 0 0 40px #0f172a !important;
}

:-webkit-any(input:-webkit-autofill:disabled, input:-webkit-autofill-selected:disabled) {
  -webkit-text-fill-color: #fff3;
  box-shadow: inset 0 0 0 40px #fff3, inset 0 0 0 40px #0f172a;
  cursor: not-allowed !important;
}

:is(input:autofill:disabled, input:-webkit-autofill-selected:disabled) {
  -webkit-text-fill-color: #fff3;
  box-shadow: inset 0 0 0 40px #fff3, inset 0 0 0 40px #0f172a;
  cursor: not-allowed !important;
}

p {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

h1 {
  cursor: default;
  letter-spacing: .0016em;
  font-family: Supera Gothic Black;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (width >= 640px) {
  h1 {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Black;
    font-size: 3rem;
    line-height: 56px;
  }
}

h2 {
  cursor: default;
  letter-spacing: .0016em;
  font-family: Supera Gothic Extra Bold;
  font-size: 1.25rem;
  line-height: 2rem;
}

@media (width >= 640px) {
  h2 {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Black;
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h3 {
  cursor: default;
  letter-spacing: .0016em;
  font-family: Supera Gothic Extra Bold;
  font-size: 1.25rem;
  line-height: 2rem;
}

@media (width >= 640px) {
  h3 {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Black;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h4 {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (width >= 640px) {
  h4 {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Extra Bold;
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

button[aria-haspopup="listbox"]:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

button[aria-haspopup="listbox"]:disabled:where(.dark, .dark *) {
  background-color: #fff3;
}

button[aria-haspopup="listbox"] {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  border-radius: .25rem;
  padding: .5rem 2.5rem .5rem 1rem;
}

button[aria-haspopup="listbox"]:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

button[aria-haspopup="listbox"] {
  cursor: pointer;
}

button[aria-haspopup="listbox"].secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

button[aria-haspopup="listbox"].secondary:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

button[aria-haspopup="listbox"].secondary:disabled {
  cursor: not-allowed;
}

button[aria-haspopup="listbox"].secondary:disabled:where(.dark, .dark *) {
  background-color: #fff3;
}

button[aria-haspopup="listbox"].secondary {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

button[aria-haspopup="listbox"].secondary:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

button[aria-haspopup="listbox"].secondary {
  outline-offset: 2px;
  border-width: 1px;
  border-radius: .25rem;
  outline: 2px solid #0000;
}

input[role="combobox"] + button[aria-haspopup="listbox"] {
  background-color: #0000;
  border-style: none;
  padding: 0 .5rem 0 0;
}

input[role="combobox"] + button[aria-haspopup="listbox"]:hover {
  background-color: #0000;
}

input[role="combobox"] + button[aria-haspopup="listbox"] {
  cursor: pointer;
}

button:not([aria-haspopup="listbox"]), button[type="button"]:not([aria-haspopup="listbox"]), button[type="submit"], button[type="reset"], a[role="button"]:not(.text-link) {
  --tw-bg-opacity: 1;
  background-color: rgb(40 65 237 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]):hover:enabled, button[type="button"]:not([aria-haspopup="listbox"]):hover:enabled, button[type="submit"]:hover:enabled, button[type="reset"]:hover:enabled, a[role="button"]:not(.text-link):hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(1 18 194 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]):where(.dark, .dark *):disabled, button[type="button"]:not([aria-haspopup="listbox"]):where(.dark, .dark *):disabled, button[type="submit"]:where(.dark, .dark *):disabled, button[type="reset"]:where(.dark, .dark *):disabled, a[role="button"]:not(.text-link):where(.dark, .dark *):disabled {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

button:not([aria-haspopup="listbox"]):disabled, button[type="button"]:not([aria-haspopup="listbox"]):disabled, button[type="submit"]:disabled, button[type="reset"]:disabled, a[role="button"]:not(.text-link):disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(74 102 244 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

button:not([aria-haspopup="listbox"]), button[type="button"]:not([aria-haspopup="listbox"]), button[type="submit"], button[type="reset"], a[role="button"]:not(.text-link) {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  cursor: pointer;
  letter-spacing: 0;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  column-gap: .375rem;
  max-height: 2rem;
  padding: .375rem .625rem;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-flex;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

button:not([aria-haspopup="listbox"]):disabled, button[type="button"]:not([aria-haspopup="listbox"]):disabled, button[type="submit"]:disabled, button[type="reset"]:disabled, a[role="button"]:not(.text-link):disabled {
  cursor: not-allowed;
}

button:not([aria-haspopup="listbox"]), button[type="button"]:not([aria-haspopup="listbox"]), button[type="submit"], button[type="reset"], a[role="button"]:not(.text-link) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

button:not([aria-haspopup="listbox"]).secondary, button[type="button"]:not([aria-haspopup="listbox"]).secondary, button[type="submit"].secondary, button[type="reset"].secondary, a[role="button"]:not(.text-link).secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).secondary:hover:enabled, button[type="button"]:not([aria-haspopup="listbox"]).secondary:hover:enabled, button[type="submit"].secondary:hover:enabled, button[type="reset"].secondary:hover:enabled, a[role="button"]:not(.text-link).secondary:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).secondary, button[type="button"]:not([aria-haspopup="listbox"]).secondary, button[type="submit"].secondary, button[type="reset"].secondary, a[role="button"]:not(.text-link).secondary {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

button:not([aria-haspopup="listbox"]).secondary:where(.dark, .dark *), button[type="button"]:not([aria-haspopup="listbox"]).secondary:where(.dark, .dark *), button[type="submit"].secondary:where(.dark, .dark *), button[type="reset"].secondary:where(.dark, .dark *), a[role="button"]:not(.text-link).secondary:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).secondary:hover:enabled:where(.dark, .dark *), button[type="button"]:not([aria-haspopup="listbox"]).secondary:hover:enabled:where(.dark, .dark *), button[type="submit"].secondary:hover:enabled:where(.dark, .dark *), button[type="reset"].secondary:hover:enabled:where(.dark, .dark *), a[role="button"]:not(.text-link).secondary:hover:enabled:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).secondary, button[type="button"]:not([aria-haspopup="listbox"]).secondary, button[type="submit"].secondary, button[type="reset"].secondary, a[role="button"]:not(.text-link).secondary {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

button:not([aria-haspopup="listbox"]).secondary:where(.dark, .dark *), button[type="button"]:not([aria-haspopup="listbox"]).secondary:where(.dark, .dark *), button[type="submit"].secondary:where(.dark, .dark *), button[type="reset"].secondary:where(.dark, .dark *), a[role="button"]:not(.text-link).secondary:where(.dark, .dark *) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button:not([aria-haspopup="listbox"]).destructive, button[type="button"]:not([aria-haspopup="listbox"]).destructive, button[type="submit"].destructive, button[type="reset"].destructive, a[role="button"]:not(.text-link).destructive {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).destructive:hover:enabled, button[type="button"]:not([aria-haspopup="listbox"]).destructive:hover:enabled, button[type="submit"].destructive:hover:enabled, button[type="reset"].destructive:hover:enabled, a[role="button"]:not(.text-link).destructive:hover:enabled {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).destructive:disabled, button[type="button"]:not([aria-haspopup="listbox"]).destructive:disabled, button[type="submit"].destructive:disabled, button[type="reset"].destructive:disabled, a[role="button"]:not(.text-link).destructive:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

button:not([aria-haspopup="listbox"]).destructive:where(.dark, .dark *):disabled, button[type="button"]:not([aria-haspopup="listbox"]).destructive:where(.dark, .dark *):disabled, button[type="submit"].destructive:where(.dark, .dark *):disabled, button[type="reset"].destructive:where(.dark, .dark *):disabled, a[role="button"]:not(.text-link).destructive:where(.dark, .dark *):disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

button:not([aria-haspopup="listbox"]).destructive:disabled, button[type="button"]:not([aria-haspopup="listbox"]).destructive:disabled, button[type="submit"].destructive:disabled, button[type="reset"].destructive:disabled, a[role="button"]:not(.text-link).destructive:disabled {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

button:not([aria-haspopup="listbox"]).quiet, button[type="button"]:not([aria-haspopup="listbox"]).quiet, button[type="submit"].quiet, button[type="reset"].quiet, a[role="button"]:not(.text-link).quiet {
  stroke: #fff;
  background-color: #0000;
}

button:not([aria-haspopup="listbox"]).quiet:hover, button[type="button"]:not([aria-haspopup="listbox"]).quiet:hover, button[type="submit"].quiet:hover, button[type="reset"].quiet:hover, a[role="button"]:not(.text-link).quiet:hover, button:not([aria-haspopup="listbox"]).quiet:disabled, button[type="button"]:not([aria-haspopup="listbox"]).quiet:disabled, button[type="submit"].quiet:disabled, button[type="reset"].quiet:disabled, a[role="button"]:not(.text-link).quiet:disabled {
  background-color: #0000;
}

button:not([aria-haspopup="listbox"]).quiet, button[type="button"]:not([aria-haspopup="listbox"]).quiet, button[type="submit"].quiet, button[type="reset"].quiet, a[role="button"]:not(.text-link).quiet {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

button:not([aria-haspopup="listbox"]).quiet:hover, button[type="button"]:not([aria-haspopup="listbox"]).quiet:hover, button[type="submit"].quiet:hover, button[type="reset"].quiet:hover, a[role="button"]:not(.text-link).quiet:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-text-opacity)) !important;
}

button:not([aria-haspopup="listbox"]).xs, button[type="button"]:not([aria-haspopup="listbox"]).xs, button[type="submit"].xs, button[type="reset"].xs, a[role="button"]:not(.text-link).xs {
  max-height: 1.5rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

button:not([aria-haspopup="listbox"]).sm, button[type="button"]:not([aria-haspopup="listbox"]).sm, button[type="submit"].sm, button[type="reset"].sm, a[role="button"]:not(.text-link).sm {
  max-height: 1.75rem;
  padding: .25rem .5rem;
}

button:not([aria-haspopup="listbox"]).md, button[type="button"]:not([aria-haspopup="listbox"]).md, button[type="submit"].md, button[type="reset"].md, a[role="button"]:not(.text-link).md {
  max-height: 2rem;
  padding: .375rem .625rem;
}

button:not([aria-haspopup="listbox"]).lg, button[type="button"]:not([aria-haspopup="listbox"]).lg, button[type="submit"].lg, button[type="reset"].lg, a[role="button"]:not(.text-link).lg {
  max-height: 2.25rem;
  padding: .5rem .75rem;
}

button:not([aria-haspopup="listbox"]).xl, button[type="button"]:not([aria-haspopup="listbox"]).xl, button[type="submit"].xl, button[type="reset"].xl, a[role="button"]:not(.text-link).xl {
  max-height: 2.5rem;
  padding: .625rem .875rem;
}

a[role="button"]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(1 18 194 / var(--tw-bg-opacity)) !important;
}

tbody, dd {
  cursor: default;
  letter-spacing: 0;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

dl:not(.rows) dt {
  cursor: default;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: .25rem;
  font-family: Inter;
  font-size: .688rem;
  font-weight: 500;
  line-height: 1.5rem;
}

dl.rows {
  grid-template-columns: auto 1fr;
  row-gap: 1rem;
  display: grid;
}

dl.rows dt {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

dl.rows dt:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

dl.rows dt {
  cursor: default;
  letter-spacing: 0;
  border-bottom-width: 1px;
  width: 144px;
  margin-bottom: 0;
  padding-right: 2rem;
  font-family: Inter;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

dl.rows dd {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

dl.rows dd:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

dl.rows dd {
  border-bottom-width: 1px;
  padding-bottom: 1rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.navlink-item {
  text-decoration: none;
  position: relative;
}

.navlink-item.active:after {
  content: "";
  background-color: #2841ed;
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  transform: translateY(-3px);
}

.navlink-item.\!active:after {
  content: "" !important;
  background-color: #2841ed !important;
  width: 100% !important;
  height: 3px !important;
  position: absolute !important;
  left: 0 !important;
  transform: translateY(-3px) !important;
}

.badge {
  cursor: default;
  letter-spacing: .16px;
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-radius: 9999px;
  width: fit-content;
  padding: 2px 8px;
  font-family: Inter;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

.tt-trigger {
  cursor: pointer;
  align-items: center;
  gap: .25rem;
  width: fit-content;
  display: flex;
  position: relative;
}

.tt-container {
  --tw-translate-x: -.5rem;
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  padding-bottom: 2rem;
  display: none;
  position: absolute;
  top: -100%;
  left: 100%;
}

.group:hover .tt-container, .tt-container:hover {
  display: flex;
}

.tt-container.tt-bottom {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  margin-bottom: 0;
  padding-top: 1.5rem;
  top: 0;
}

.tt {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.tt:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.tt {
  --tw-shadow: 0px 8px 12px 0px #0c1014d9, 0px 0px 1px 0px #0c1014cf;
  --tw-shadow-colored: 0px 8px 12px 0px var(--tw-shadow-color), 0px 0px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(.5px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
  border-radius: .25rem;
  min-width: 320px;
  padding: .5rem 1rem;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #4a66f4 #334155;
  position: relative;
}

.custom-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  margin-right: 16px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #334155;
  border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a66f4;
  border-radius: 20px;
  min-height: 40px;
}

.fade-container {
  opacity: 0;
  animation: .5s forwards fadeIn;
}

.fade-container.hide {
  animation: .5s forwards fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-inset-2 {
  inset: -.5rem;
}

.-inset-2\.5 {
  inset: -.625rem;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[-10px\] {
  bottom: -10px;
}

.bottom-\[32px\] {
  bottom: 32px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-\[32px\] {
  right: 32px;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-5 {
  top: 1.25rem;
}

.top-\[-40px\] {
  top: -40px;
}

.top-\[48px\] {
  top: 48px;
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[10000000\] {
  z-index: 10000000;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[2000\] {
  z-index: 2000;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-\[200px\] {
  margin-left: 200px;
}

.ml-px {
  margin-left: 1px;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.size-0 {
  width: 0;
  height: 0;
}

.size-0\.5 {
  width: .125rem;
  height: .125rem;
}

.size-11 {
  width: 2.75rem;
  height: 2.75rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-2\.5 {
  width: .625rem;
  height: .625rem;
}

.size-20 {
  width: 5rem;
  height: 5rem;
}

.size-3 {
  width: .75rem;
  height: .75rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-48 {
  width: 12rem;
  height: 12rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-7 {
  width: 1.75rem;
  height: 1.75rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-full {
  width: 100%;
  height: 100%;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[33px\] {
  height: 33px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[480px\] {
  height: 480px;
}

.h-\[560px\] {
  height: 560px;
}

.h-\[75vh\] {
  height: 75vh;
}

.h-\[99vh\] {
  height: 99vh;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-1 {
  min-height: .25rem;
}

.min-h-16 {
  min-height: 4rem;
}

.min-h-\[11\.5rem\] {
  min-height: 11.5rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/5 {
  width: 20%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-8 {
  width: 2rem;
}

.w-\[118px\] {
  width: 118px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[220px\] {
  width: 220px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[424px\] {
  width: 424px;
}

.w-\[514px\] {
  width: 514px;
}

.w-\[516px\] {
  width: 516px;
}

.w-\[550px\] {
  width: 550px;
}

.w-\[672px\] {
  width: 672px;
}

.w-\[702px\] {
  width: 702px;
}

.w-\[80px\] {
  width: 80px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[128px\] {
  min-width: 128px;
}

.min-w-\[300px\] {
  min-width: 300px;
}

.min-w-full {
  min-width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-w-72 {
  max-width: 18rem;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[468px\] {
  max-width: 468px;
}

.max-w-\[672px\] {
  max-width: 672px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.origin-top {
  transform-origin: top;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-5 {
  --tw-translate-y: -1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-12 {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[200px\] {
  --tw-translate-y: 200px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-\[ping_1\.4s_ease-in-out_infinite\] {
  animation: 1.4s ease-in-out infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[42px\] {
  gap: 42px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-slate-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-divide-opacity));
}

.divide-slate-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-divide-opacity));
}

.self-end {
  align-self: flex-end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.\!truncate {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.text-clip {
  text-overflow: clip;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-wrap {
  text-wrap: wrap;
}

.text-pretty {
  text-wrap: pretty;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[14px\] {
  border-radius: 14px;
}

.rounded-\[1px\] {
  border-radius: 1px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-\[\#677283\] {
  --tw-border-opacity: 1;
  border-color: rgb(103 114 131 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-\[\#08BDBA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 189 186 / var(--tw-bg-opacity));
}

.bg-\[\#3E4758\] {
  --tw-bg-opacity: 1;
  background-color: rgb(62 71 88 / var(--tw-bg-opacity));
}

.bg-\[\#F8FAFC0F\] {
  background-color: #f8fafc0f;
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-brand-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(174 195 252 / var(--tw-bg-opacity));
}

.bg-brand-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 102 244 / var(--tw-bg-opacity));
}

.bg-brand-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 241 255 / var(--tw-bg-opacity));
}

.bg-brand-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(40 65 237 / var(--tw-bg-opacity));
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-500\/55 {
  background-color: #6b72808c;
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-slate-100\/20 {
  background-color: #f1f5f933;
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-50\/10 {
  background-color: #f8fafc1a;
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/20 {
  background-color: #fff3;
}

.bg-opacity-\[6\%\] {
  --tw-bg-opacity: 6%;
}

.bg-\[radial-gradient\(ellipse_at_top_left\,_\#8cb4f6_0\%\,_\#003bac_34\%\,_\#001325_68\%\,_\#002e80_100\%\)\] {
  background-image: radial-gradient(at 0 0, #8cb4f6 0%, #003bac 34%, #001325 68%, #002e80 100%);
}

.bg-none {
  background-image: none;
}

.fill-\[\#08BDBA\] {
  fill: #08bdba;
}

.fill-\[\#788598\] {
  fill: #788598;
}

.fill-\[\#d9f1ff\] {
  fill: #d9f1ff;
}

.fill-brand-500 {
  fill: #2841ed;
}

.fill-current {
  fill: currentColor;
}

.fill-gray-200 {
  fill: #e5e7eb;
}

.fill-gray-300 {
  fill: #d1d5db;
}

.fill-gray-500 {
  fill: #6b7280;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-red-300 {
  fill: #fca5a5;
}

.fill-red-500 {
  fill: #ef4444;
}

.fill-slate-500 {
  fill: #64748b;
}

.fill-slate-900 {
  fill: #0f172a;
}

.fill-transparent {
  fill: #0000;
}

.fill-white {
  fill: #fff;
}

.stroke-\[\#08BDBA\] {
  stroke: #08bdba;
}

.stroke-\[\#788598\] {
  stroke: #788598;
}

.stroke-brand-300 {
  stroke: #92abfa;
}

.stroke-brand-400 {
  stroke: #4a66f4;
}

.stroke-gray-200 {
  stroke: #e5e7eb;
}

.stroke-gray-400 {
  stroke: #9ca3af;
}

.stroke-green-600 {
  stroke: #16a34a;
}

.stroke-red-500 {
  stroke: #ef4444;
}

.stroke-slate-400 {
  stroke: #94a3b8;
}

.stroke-transparent {
  stroke: #0000;
}

.stroke-white {
  stroke: #fff;
}

.p-1 {
  padding: .25rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pt-\[48px\] {
  padding-top: 48px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.\!capitalize {
  text-transform: capitalize !important;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-\[\#151C23\] {
  --tw-text-opacity: 1;
  color: rgb(21 28 35 / var(--tw-text-opacity));
}

.text-\[\#23922F\] {
  --tw-text-opacity: 1;
  color: rgb(35 146 47 / var(--tw-text-opacity));
}

.text-\[\#2841ED\] {
  --tw-text-opacity: 1;
  color: rgb(40 65 237 / var(--tw-text-opacity));
}

.text-\[\#2A313F\] {
  --tw-text-opacity: 1;
  color: rgb(42 49 63 / var(--tw-text-opacity));
}

.text-\[\#3E4758\] {
  --tw-text-opacity: 1;
  color: rgb(62 71 88 / var(--tw-text-opacity));
}

.text-\[\#B36B27\] {
  --tw-text-opacity: 1;
  color: rgb(179 107 39 / var(--tw-text-opacity));
}

.text-\[\#C01F05\] {
  --tw-text-opacity: 1;
  color: rgb(192 31 5 / var(--tw-text-opacity));
}

.text-\[\#E29036\] {
  --tw-text-opacity: 1;
  color: rgb(226 144 54 / var(--tw-text-opacity));
}

.text-\[\#EF463B\] {
  --tw-text-opacity: 1;
  color: rgb(239 70 59 / var(--tw-text-opacity));
}

.text-\[\#FCFDFE\] {
  --tw-text-opacity: 1;
  color: rgb(252 253 254 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-brand {
  --tw-text-opacity: 1;
  color: rgb(1 18 194 / var(--tw-text-opacity));
}

.text-brand-300 {
  --tw-text-opacity: 1;
  color: rgb(146 171 250 / var(--tw-text-opacity));
}

.text-brand-500 {
  --tw-text-opacity: 1;
  color: rgb(40 65 237 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tooltip {
  --tw-shadow: 0px 8px 12px 0px #0c1014d9, 0px 0px 1px 0px #0c1014cf;
  --tw-shadow-colored: 0px 8px 12px 0px var(--tw-shadow-color), 0px 0px 1px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-0 {
  outline-width: 0;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-black\/5 {
  --tw-ring-color: #0000000d;
}

.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity));
}

.ring-slate-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity));
}

.ring-slate-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-\[600ms\] {
  transition-duration: .6s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius);
}

.scrollbar::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}

.scrollbar::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}

.scrollbar::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
  border-radius: var(--scrollbar-corner-radius);
}

.scrollbar::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
}

.scrollbar::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
}

.scrollbar {
  scrollbar-width: auto;
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

.scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width, 16px);
  height: var(--scrollbar-height, 16px);
  display: block;
}

.scrollbar-track-transparent {
  --scrollbar-track: transparent !important;
}

.scrollbar-thumb-brand-400 {
  --scrollbar-thumb: #4a66f4 !important;
}

.scrollbar-thumb-slate-600 {
  --scrollbar-thumb: #475569 !important;
}

.checked\:border-default:checked {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.checked\:border-default:checked:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.hover\:bg-default:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-default:hover:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.hover\:text-color:hover {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.hover\:text-color:hover:where(.dark, .dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:fill-subdued:hover {
  fill: #6b7280;
}

.hover\:border-default:hover {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.hover\:border-default:hover:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

@media (width >= 640px) {
  .sm\:display-s {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Black;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (width >= 1024px) {
  .lg\:display-l {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Black;
    font-size: 3rem;
    line-height: 56px;
  }

  .lg\:display-m {
    cursor: default;
    letter-spacing: .0016em;
    font-family: Supera Gothic Black;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .lg\:\[\&\>\*\:last-child\]\:fade-container > :last-child {
    opacity: 0;
    animation: .5s forwards fadeIn;
  }

  .lg\:\[\&\>\*\:last-child\]\:fade-container > :last-child.hide {
    animation: .5s forwards fadeOut;
  }
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.placeholder\:text-slate-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.placeholder\:text-transparent::placeholder {
  color: #0000;
}

.first\:flex:first-child {
  display: flex;
}

.first\:w-full:first-child {
  width: 100%;
}

.first\:flex-col:first-child {
  flex-direction: column;
}

.first\:gap-3:first-child {
  gap: .75rem;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:w-full:last-child {
  width: 100%;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:fill-brand-400 {
  fill: #4a66f4;
}

.group:hover .group-hover\:fill-gray-200 {
  fill: #e5e7eb;
}

.group:hover .group-hover\:fill-white {
  fill: #fff;
}

.group:hover .group-hover\:stroke-brand-400 {
  stroke: #4a66f4;
}

.group:hover .group-hover\:stroke-gray-200 {
  stroke: #e5e7eb;
}

.group:hover .group-hover\:stroke-slate-200 {
  stroke: #e2e8f0;
}

.group:hover .group-hover\:stroke-white {
  stroke: #fff;
}

.data-\[closed\]\:translate-x-full[data-closed] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:opacity-0[data-closed] {
  opacity: 0;
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-transparent:hover {
  border-color: #0000;
}

.hover\:bg-\[\#96b2ce1f\]:hover {
  background-color: #96b2ce1f;
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.hover\:fill-brand-400:hover {
  fill: #4a66f4;
}

.hover\:fill-slate-600:hover {
  fill: #475569;
}

.hover\:fill-white:hover {
  fill: #fff;
}

.hover\:stroke-black:hover {
  stroke: #000;
}

.hover\:stroke-brand-400:hover {
  stroke: #4a66f4;
}

.hover\:stroke-white:hover {
  stroke: #fff;
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.hover\:text-slate-200:hover {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.hover\:text-slate-300:hover {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:border-transparent:focus {
  border-color: #0000;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.focus\:ring-slate-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity));
}

.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:placeholder\:text-transparent:focus::placeholder {
  color: #0000;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-transparent:disabled {
  border-color: #0000;
}

.disabled\:bg-slate-400:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-header-group {
    display: table-header-group;
  }

  .sm\:table-row {
    display: table-row;
  }

  .sm\:min-w-\[348px\] {
    min-width: 348px;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-14 {
    gap: 3.5rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pr-3 {
    padding-right: .75rem;
  }

  .sm\:pr-3\.5 {
    padding-right: .875rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (width >= 768px) {
  .md\:fixed {
    position: fixed;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-\[320px\] {
    margin-left: 320px;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:size-auto {
    width: auto;
    height: auto;
  }

  .md\:h-\[56px\] {
    height: 56px;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:min-h-16 {
    min-height: 4rem;
  }

  .md\:min-h-44 {
    min-height: 11rem;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:w-\[136px\] {
    width: 136px;
  }

  .md\:w-\[248px\] {
    width: 248px;
  }

  .md\:w-\[260px\] {
    width: 260px;
  }

  .md\:w-\[400px\] {
    width: 400px;
  }

  .md\:w-\[450px\] {
    width: 450px;
  }

  .md\:w-\[480px\] {
    width: 480px;
  }

  .md\:w-\[500px\] {
    width: 500px;
  }

  .md\:w-\[56px\] {
    width: 56px;
  }

  .md\:w-\[702px\] {
    width: 702px;
  }

  .md\:min-w-\[248px\] {
    min-width: 248px;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-\[136px\] {
    max-width: 136px;
  }

  .md\:max-w-\[248px\] {
    max-width: 248px;
  }

  .md\:max-w-\[320px\] {
    max-width: 320px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:pl-7 {
    padding-left: 1.75rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:fixed {
    position: fixed;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:ml-\[280px\] {
    margin-left: 280px;
  }

  .lg\:ml-\[480px\] {
    margin-left: 480px;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:size-4 {
    width: 1rem;
    height: 1rem;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:max-h-screen {
    max-height: 100vh;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-\[200px\] {
    width: 200px;
  }

  .lg\:w-\[672px\] {
    width: 672px;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-\[280px\] {
    max-width: 280px;
  }

  .lg\:max-w-\[480px\] {
    max-width: 480px;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-\[100px\] {
    gap: 100px;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-\[100px\] {
    padding-left: 100px;
    padding-right: 100px;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:first\:w-\[400px\]:first-child {
    width: 400px;
  }

  .lg\:first\:max-w-\[400px\]:first-child {
    max-width: 400px;
  }

  .lg\:last\:max-w-\[500px\]:last-child {
    max-width: 500px;
  }
}

@media (width >= 1280px) {
  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:gap-\[120px\] {
    gap: 120px;
  }

  .xl\:px-\[180px\] {
    padding-left: 180px;
    padding-right: 180px;
  }
}

@media (width >= 1536px) {
  .\32 xl\:px-\[300px\] {
    padding-left: 300px;
    padding-right: 300px;
  }
}

@media (height >= 800px) {
  .xtall\:mb-20 {
    margin-bottom: 5rem;
  }

  .xtall\:mt-8 {
    margin-top: 2rem;
  }

  .xtall\:max-h-\[54vh\] {
    max-height: 54vh;
  }
}

@media (height <= 799px) {
  .tall\:max-h-\[52vh\] {
    max-height: 52vh;
  }

  .tall\:max-h-\[53vh\] {
    max-height: 53vh;
  }
}

@media (height <= 699px) {
  .m-height\:max-h-\[50vh\] {
    max-height: 50vh;
  }
}

@media (height <= 600px) {
  .short\:max-h-\[40vh\] {
    max-height: 40vh;
  }
}

.dark\:divide-slate-700:where(.dark, .dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-divide-opacity));
}

.dark\:border-slate-700:where(.dark, .dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.dark\:bg-slate-800:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.dark\:bg-slate-900:where(.dark, .dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.dark\:ring-slate-700:where(.dark, .dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}

.disabled\:dark\:bg-slate-900:where(.dark, .dark *):disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.\[\&\>\*\:first-child\]\:flex > :first-child {
  display: flex;
}

.\[\&\>\*\:first-child\]\:w-full > :first-child {
  width: 100%;
}

.\[\&\>\*\:first-child\]\:flex-col > :first-child {
  flex-direction: column;
}

.\[\&\>\*\:first-child\]\:gap-3 > :first-child {
  gap: .75rem;
}

@media (width >= 1024px) {
  .lg\:\[\&\>\*\:first-child\]\:w-\[400px\] > :first-child {
    width: 400px;
  }
}

.\[\&\>\*\:last-child\]\:mb-4 > :last-child {
  margin-bottom: 1rem;
}

.\[\&\>\*\:last-child\]\:w-full > :last-child {
  width: 100%;
}

@media (width >= 1024px) {
  .lg\:\[\&\>\*\:last-child\]\:mb-0 > :last-child {
    margin-bottom: 0;
  }

  .lg\:\[\&\>\*\:last-child\]\:box-content > :last-child {
    box-sizing: content-box;
  }

  .lg\:\[\&\>\*\:last-child\]\:max-w-\[520px\] > :last-child {
    max-width: 520px;
  }

  .lg\:\[\&\>\*\:last-child\]\:pr-4 > :last-child {
    padding-right: 1rem;
  }
}

.\[\&\>\*\]\:cursor-pointer > * {
  cursor: pointer;
}

.\[\&\>dd\]\:col-span-2 > dd {
  grid-column: span 2 / span 2;
}

.\[\&\>dd\]\:break-words > dd {
  overflow-wrap: break-word;
}

.\[\&\>dt\]\:col-span-1 > dt {
  grid-column: span 1 / span 1;
}
/*# sourceMappingURL=index.3c502a14.css.map */
